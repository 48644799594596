import React from 'react';

function Loading() {
  return (
    <div className='loading-cont'>
      <div className="wrapper">
      <img src="/Assets/khanda.svg" alt="Khanda as a loading animation" />
      <p>LOADING...</p>
      </div>
    </div>
  )
}

export default Loading;