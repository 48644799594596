import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from './components/Loading';
const Navbar = lazy(() => import("./components/Navbar"));
const Home = lazy(() => import("./components/Home"));
const Audio = lazy(() => import('./components/Audio'));
const About = lazy(() => import('./components/About'));
const Services = lazy(() => import('./components/Services'));
const Events = lazy(() => import('./components/Events'));
const Articles = lazy(() => import('./components/Articles'));
const Drawings = lazy(() => import('./components/Drawings'));
const Magazine = lazy(() => import('./components/Magazine/Magazine'));
const Donate = lazy(() => import('./components/Donate'));
const Contact = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));

function App() {

  const routes = [
    {
      path: '/magazine',
      element: <Magazine />
    }, 
  ];

  const element = useRoutes(routes);

  return (
    <>
    <Suspense fallback={<Loading />}>
      <Navbar />

      <section>
        {element}
      </section>

      {element === null ? (
        <>
        <Audio />
      <section id="#">
        <Home />
      </section>

      <section id="about">
        <About />
      </section>

      <section id='services'>
        <Services />
      </section>

      <section id="events">
        <Events />
      </section>

      <section className="article">
        <Articles />
      </section>

      <section className='drawings'>
        <Drawings />
      </section>

      <section id="donate">
        <Donate />
      </section>

      <section id="contact">
        <Contact />
      </section>

        </>
      ) : null}

      <Footer />
      </Suspense>
    </>
  );
}

export default App;